"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextFilter = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const FilterComponent_1 = require("./FilterComponent");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const TextFilter = (props) => {
    const searchRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        if (searchRef.current) {
            searchRef.current.setText((_a = props.value) !== null && _a !== void 0 ? _a : '');
        }
    }, [props.value]);
    const { format, id, label, onChange, placeholder, value } = props;
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({}, props),
        react_1.default.createElement(general_1.SearchInput, { className: 'filter', defaultValue: value, id: (0, lib_1.getFilterId)(id), onChange: (query = '') => onChange({
                [id]: {
                    format,
                    value: query
                }
            }), placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY((0, sonar_ts_lib_1.capitalize)(label !== null && label !== void 0 ? label : '')), ref: searchRef })));
};
exports.TextFilter = TextFilter;
