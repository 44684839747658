"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLockStateToggleButton = void 0;
require("./UserControls.scss");
const xuder_1 = require("@sonar-software/xuder");
const antd_1 = require("antd");
const selectors_1 = require("@lib/selectors");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.admin.patch);
const UserLockStateToggleButton = ({ onChange = () => null, shardName, user, userIsLocked }) => {
    const { errorNotification } = (0, _hooks_1.useAppNotifications)();
    const patchUserAdmin = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersAdmin);
    const resetPatchUserAdmin = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPatchCoreUsersAdmin);
    const processPatch = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetPatchUserAdmin,
        stateSelector: selectors.state,
        statusHandlers: {
            [xuder_1.AsyncStateStatus.Received]: onChange,
            [xuder_1.AsyncStateStatus.Errored]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT),
            [xuder_1.AsyncStateStatus.Failed]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT)
        }
    });
    const onClick = (0, react_1.useCallback)(() => {
        processPatch.watchNextCall();
        patchUserAdmin({
            isLocked: !userIsLocked,
            shardName,
            userId: user.id
        });
    }, [processPatch, patchUserAdmin, userIsLocked, shardName, user.id]);
    return (react_1.default.createElement("div", { style: { display: 'inline-block' } },
        userIsLocked &&
            react_1.default.createElement(antd_1.Button, { className: 'unlock-user-button', danger: true, icon: react_1.default.createElement(icons_1.UnlockOutlined, null), loading: processPatch.state.isProcessing, onClick: onClick }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UNLOCK),
        !userIsLocked &&
            react_1.default.createElement(antd_1.Button, { className: 'lock-user-button', icon: react_1.default.createElement(icons_1.LockOutlined, null), loading: processPatch.state.isProcessing, onClick: onClick }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LOCK)));
};
exports.UserLockStateToggleButton = UserLockStateToggleButton;
