"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionFilter = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const filters_1 = require("@ui/table-modifier/filters");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_2 = require("@periscopes/general/filters");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const noSection = '""';
const sectionsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.setupAuditTrail.sections.get);
const SectionFilter = (props) => {
    var _a, _b;
    const { data } = (0, _hooks_1.useAsyncState)(sectionsSelectors);
    const getSetupAuditTrailSections = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSetupAuditTrailSections);
    const valueWithEmpty = (_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.map((prefix) => (Number(prefix) === 0 ? noSection : prefix))) !== null && _b !== void 0 ? _b : [];
    (0, _hooks_1.useOnMount)(() => {
        getSetupAuditTrailSections();
    });
    const options = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.map((section) => ({
            label: section ? section : sonar_ts_constants_1.COPY_SPECIFIC.NO_SECTION,
            value: section ? section : noSection
        }))) !== null && _a !== void 0 ? _a : [];
    }, [data]);
    const { format, id, label, onChange } = props;
    return (react_1.default.createElement(filters_1.FilterComponent, Object.assign({}, props, { tagProps: {
            value: options === null || options === void 0 ? void 0 : options.filter((option) => valueWithEmpty === null || valueWithEmpty === void 0 ? void 0 : valueWithEmpty.includes(option.value)).map((option) => option.label).join(', ')
        } }),
        react_1.default.createElement(filters_2.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), maxTagCount: 'responsive', mode: 'multiple', onChange: (sections) => {
                onChange({
                    [id]: {
                        format,
                        value: sections !== null && sections !== void 0 ? sections : []
                    }
                });
            }, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, value: valueWithEmpty })));
};
exports.SectionFilter = SectionFilter;
