"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrail = void 0;
require("./SetupAuditTrail.scss");
const MonitorMenu_1 = require("./MonitorMenu");
const SetupAuditTrailRule_1 = require("./SetupAuditTrailRule");
const text_1 = require("@ui/text");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const conditionLogicOptions = [
    {
        label: sonar_ts_constants_1.COPY_SPECIFIC.ALL_CONDITIONS_ARE_MET,
        value: 'and'
    },
    {
        label: sonar_ts_constants_1.COPY_SPECIFIC.ANY_CONDITIONS_ARE_MET,
        value: 'or'
    }
];
const SetupAuditTrail = ({ monitor }) => {
    const selectedRuleType = antd_1.Form.useWatch(['monitorConfig', 'ruleType']);
    const initialRules = (0, react_1.useMemo)(() => {
        var _a;
        if (monitor.monitorConfig.rules.length > 0) {
            return monitor.monitorConfig.rules;
        }
        const configDefault = ((_a = MonitorMenu_1.monitorMenuConfigs.find((x) => x.type === sonar_core_1.PulseMonitorType.SetupAuditTrail)) === null || _a === void 0 ? void 0 : _a.default).monitorConfig.rules;
        if (configDefault) {
            return configDefault;
        }
        return [];
    }, [monitor.monitorConfig.rules]);
    return (react_1.default.createElement(antd_1.Space, { className: 'setup-audit-trail-details', direction: 'vertical' },
        react_1.default.createElement(antd_1.Flex, { gap: 32 },
            react_1.default.createElement(antd_1.Flex, { justify: 'center', vertical: true },
                react_1.default.createElement(text_1.SonarText, { className: 'subheading' }, sonar_ts_constants_1.COPY_SPECIFIC.EVENT),
                react_1.default.createElement(text_1.SonarText, { className: 'event-name' }, sonar_ts_constants_1.COPY_SPECIFIC.SETUP_AUDIT_TRAIL_EVENT)),
            react_1.default.createElement(antd_1.Alert, { action: react_1.default.createElement("a", { href: sonar_ts_constants_1.LINKS.SETUP_AUDIT_TRAIL_HELP_ARTICLE, rel: 'noopener', target: '_blank' }, sonar_ts_constants_1.COPY_SPECIFIC.LEARN_MORE), className: 'more-info-link', message: sonar_ts_constants_1.COPY_SPECIFIC.TRACK_SETUP_AUDIT_TRAIL_ACTIVITY, showIcon: true, type: 'info' })),
        react_1.default.createElement(text_1.SonarText, { className: 'subheading' }, sonar_ts_constants_1.COPY_SPECIFIC.CONDITION_LOGIC),
        react_1.default.createElement(antd_1.Form.Item, { className: 'condition-logic-selector', initialValue: monitor.monitorConfig.ruleType, name: ['monitorConfig', 'ruleType'] },
            react_1.default.createElement(antd_1.Select, { options: conditionLogicOptions })),
        react_1.default.createElement(antd_1.Form.List, { initialValue: initialRules, name: ['monitorConfig', 'rules'] }, (fields, { add, remove }) => react_1.default.createElement("div", { className: 'rule-grid' },
            react_1.default.createElement(react_if_1.When, { condition: fields.length },
                react_1.default.createElement("div", { className: 'empty-grid-col' }),
                react_1.default.createElement("div", null, sonar_ts_constants_1.COPY_SPECIFIC.CONDITION),
                react_1.default.createElement("div", null, sonar_ts_constants_1.COPY_SPECIFIC.OPERATOR),
                react_1.default.createElement("div", null, sonar_ts_constants_1.COPY_SPECIFIC.VALUE),
                react_1.default.createElement("div", { className: 'empty-grid-col' })),
            fields.map((field) => react_1.default.createElement(SetupAuditTrailRule_1.SetupAuditTrailRule, { canDelete: fields.length > 1, field: field, key: field.key, onDelete: () => remove(field.name), ruleType: selectedRuleType })),
            react_1.default.createElement("div", { className: 'empty-grid-col' }),
            react_1.default.createElement(antd_1.Button, { className: 'add-condition-button', onClick: () => add() }, sonar_ts_constants_1.COPY_SPECIFIC.ADD_CONDITION)))));
};
exports.SetupAuditTrail = SetupAuditTrail;
